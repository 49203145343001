import LoginPage from '../../views/auth/Login.vue';
import DashboardPage from '../../views/pages/Dashboard.vue';
import AccoutPage from '../../views/pages/Account.vue';
import ImportLogIndex from '../../views/pages/import/Index.vue';
import ImportLogPage from '../../views/pages/import/List.vue';
import ImportLogDetailPage from '../../views/pages/import/_Id.vue';
import EntrancePage from '../../views/pages/entrance/Index.vue';
import EntranceDetailPage from '../../views/pages/entrance/Detail.vue';
import CardGuestPage from '../../views/pages/cardguest/Index.vue';
import StaffPage from '../../views/pages/staff/Index.vue';
import DatabaseIndexPage from '../../views/pages/database/Index.vue';
import DatabaseListPage from '../../views/pages/database/List.vue';
import DatabaseIdPage from '../../views/pages/database/_Id.vue';
import EmailSendPage from '../../views/pages/EmailSend.vue';
import SettingIndexPage from '../../views/pages/setting/Index.vue';
import SettingEmailPage from '../../views/pages/setting/EmailConfig.vue';
import SettingEmailTemplatePage from '../../views/pages/setting/EmailTemplate.vue';
import EmailHistoryPage from '../../views/pages/EmailHistory.vue';
import ClubPage from '../../views/pages/Club.vue';
import ResourcePage from '../../views/pages/resource/Index.vue';
import RequestResourcePage from '../../views/pages/request/Index.vue';
import ClubRequestResourcePage from '../../views/pages/club-request/Index.vue';
import EventHistoryPage from '../../views/pages/event/Index.vue';
import EventGroupPage from '../../views/pages/event/EventGroup.vue';
import EventEmailTemplatePage from '../../views/pages/event/EmailTemplate.vue';
import EventEmailConfigPage from '../../views/pages/event/EmailConfig.vue';

import APPJSH from '../helper/ConstantHelper';

const routes = [
  // Auth
  {
    path: '/login',
    component: LoginPage,
  },
  // Dashboard
  {
    path: '/',
    component: DashboardPage,
    meta: {
      title: 'ダッシュボード',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL, APPJSH.ROLES.PTA, APPJSH.ROLES.CLUB],
    }
  },
  // Account
  {
    path: '/account',
    component: AccoutPage,
    meta: {
      title: 'アカウント管理',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL],
    }
  },
  // Import log
  {
    path: '/import',
    component: ImportLogIndex,
    meta: {
      title: 'データインポート記録',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL],
    },
    children: [
      {
        path: '',
        component: ImportLogPage,
        meta: {
          title: 'データインポート記録',
          roles: [APPJSH.ROLES.SCHOOL],
        }
      },
      {
        path: ':id',
        component: ImportLogDetailPage,
        meta: {
          title: 'データインポート記録',
          roles: [APPJSH.ROLES.SCHOOL],
        }
      }
    ]
  },
  // Entrance
  {
    path: '/entrance',
    component: EntrancePage,
    meta: {
      title: '入出校管理',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL],
    }
  },
  {
    path: '/entrance/detail',
    component: EntranceDetailPage,
    meta: {
      title: '入出校管理',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL],
    }
  },
  // Staff manager
  {
    path: '/staff',
    component: StaffPage,
    meta: {
      title: '職員管理',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL],
    }
  },
  // card guest
  {
    path: '/card-guest',
    component: CardGuestPage,
    meta: {
      title: 'ゲスト管理',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL],
    }
  },
  // Database
  {
    path: '/database',
    component: DatabaseIndexPage,
    meta: {
      title: 'データベース',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL],
    },
    children: [
      {
        path: '',
        component: DatabaseListPage,
        meta: {
          title: 'データベース',
          roles: [APPJSH.ROLES.SCHOOL],
        }
      },
      {
        path: ':id',
        component: DatabaseIdPage,
        meta: {
          title: '生徒情報',
          roles: [APPJSH.ROLES.SCHOOL],
        }
      }
    ]
  },
  {
    path: '/send-email',
    component: EmailSendPage,
    meta: {
      title: 'メール配信',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL, APPJSH.ROLES.PTA],
    }
  },
  // Email History
  {
    path: '/email-history',
    component: EmailHistoryPage,
    meta: {
      title: 'メール配信履歴',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL, APPJSH.ROLES.PTA],
    },
  },
  // Setting
  {
    path: '/setting',
    component: SettingIndexPage,
    meta: {
      title: '設定',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL, APPJSH.ROLES.PTA],
    },
    children: [
      {
        path: 'email-config',
        component: SettingEmailPage,
        meta: {
          title: 'メール送信設定',
          roles: [APPJSH.ROLES.SCHOOL],
        }
      },
      {
        path: 'email-template',
        component: SettingEmailTemplatePage,
        meta: {
          title: 'テンプレート設定',
          roles: [APPJSH.ROLES.SCHOOL, APPJSH.ROLES.PTA],
        }
      }
    ]
  },
  {
    path: '/club',
    component: ClubPage,
    meta: {
      title: 'クラブ一覧',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL, APPJSH.ROLES.CLUB],
    }
  },
  {
    path: '/resources',
    component: ResourcePage,
    meta: {
      title: '施設利用管理',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL],
    }
  },
  // {
  //   path: '/request-resources',
  //   component: RequestResourcePage,
  //   meta: {
  //     title: '資源申請',
  //     requiresAuth: true,
  //     roles: [APPJSH.ROLES.SCHOOL],
  //   }
  // },
  {
    path: '/request-resource',
    component: ClubRequestResourcePage,
    meta: {
      title: '施設利用',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL, APPJSH.ROLES.CLUB],
    }
  },
  {
    path: '/event-history',
    component: EventHistoryPage,
    meta: {
      title: 'イベントチケット',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL],
    }
  },
  {
    path: '/event-group',
    component: EventGroupPage,
    meta: {
      title: 'グループ管理',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL],
    }
  },
  {
    path: '/event/email-template',
    component: EventEmailTemplatePage,
    meta: {
      title: 'テンプレート設定',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL],
    }
  },
  {
    path: '/event/email-config',
    component: EventEmailConfigPage,
    meta: {
      title: 'メール設定',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL],
    }
  },
  // exception
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
    meta: {
      requiresAuth: true,
    }
  }
];

export default routes;
