<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue'
const $root = getCurrentInstance()?.appContext.config.globalProperties
import { MagnifyingGlassIcon, TrashIcon } from '@heroicons/vue/24/outline';

import DateTimeHelper from 'helper/DatetimeHelper';

import { useEventTicket } from 'store/event-ticket';
const eventTicketStore = useEventTicket()

import EventGroupModal from './EventGroupModal.vue';

onMounted(async () => {
    fetchData()
})

const fetchData = async () => {
    $root.overlay.loading = true
    await eventTicketStore.fetchGroups()
    $root.overlay.loading = false
}
const refEventGroupModal = ref(null)
const showModel = (item) => {
    if (item.id > 0) {
        eventTicketStore.group = Object.assign({}, item)
    }
    else {
        eventTicketStore.group = Object.assign({}, eventTicketStore.intGroup)
    }
    refEventGroupModal.value.open()
}

const doSearch = () => {
    fetchData()
}
</script>
<template>
    <div class="w-full bg-white rounded-md p-4 flex flex-col gap-2">
        <div class="flex justify-between">
            <button class="px-4 h-8 bg-primary shadow text-white rounded hover:opacity-80"
                @click.prevent="showModel({})">
                新規登録
            </button>
        </div>
        <hr>
        <div class="flex justify-between">
            <div class="flex gap-2">
                <input type="search" class="px-4 h-8 border border-gray-200 rounded w-56 focus:border-none"
                    v-model="eventTicketStore.filterGroup.text" @keydown.enter="doSearch()" />
                <button
                    class="px-4 h-8 bg-primary text-white rounded hover:opacity-80 group flex items-center gap-1 transition duration-300 delay-75 ease-in-out"
                    @click.prevent="doSearch()">
                    <MagnifyingGlassIcon
                        class="w-5 h-5 group-hover:scale-110 transition duration-300 delay-75 ease-in-out" />検索
                </button>
            </div>
        </div>

        <table class="w-full table">
            <thead class="border-t-2 border-t-secondary border-b border-b-gray-200 bg-gray-100 text-xs">
                <tr>
                    <th v-for="header in eventTicketStore.headersGroup" class="font-normal p-2"
                        :class="header.field == 'count' ? 'text-center' : 'text-left'"
                        :style="`width:${header.width};`">
                        {{ header.text }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <template v-if="eventTicketStore.groups.length">
                    <tr v-for="(item, index) in eventTicketStore.groups" class="border-b border-b-gray-200">
                        <td
                            class="p-2 text-blue-500 font-semibold hover:underline hover:opacity-80 hover:cursor-pointer">
                            <div @click.prevent="showModel(item)">
                                {{ item.name }}
                            </div>
                        </td>
                        <td class="p-2">{{ item.reason }}</td>
                        <td class="p-2">{{ item.status == 1 ? '有効' : '無効' }}</td>
                        <td class="p-2">{{ DateTimeHelper.format(item.start_time, 'YYYY/MM/DD(ddd)　HH:mm') }}</td>
                        <td class="p-2">{{ DateTimeHelper.format(item.end_time, 'YYYY/MM/DD(ddd)　HH:mm') }}</td>
                    </tr>
                </template>
                <tr v-else-if="!$root.overlay.loading">
                    <td class="p-2 text-center text-xs text-grey" :colspan="eventTicketStore.headersGroup.length">
                        データがありません
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
    <EventGroupModal ref="refEventGroupModal" />
</template>
