import { defineStore } from 'pinia'
import ValidateHelper from 'helper/ValidateHelper'
export const useEventTicket = defineStore('event-ticket', {
  state: () => ({
    // list
    headers: [
      { text: '名前', field: 'name' },
      { text: 'グループ', field: 'group' },
      { text: '性別', field: 'gender' },
      { text: 'メールアドレス', field: 'email' },
      { text: '電話番号', field: 'phone' },
      { text: '生年月日', field: 'date_of_birth', },
      { text: '現住所', field: 'address', },
      { text: '状態', field: 'status', },
      { text: 'メール送信状態', field: 'send_email', },
      { text: 'QR_Code', field: 'qr_code_url', },
    ],
    items: [],
    item: {},
    intItem: { id: 0, groups: [], kanji_name: '', romaji_name: '', gender: '', email: '', phone: '', date_of_birth: '', address: '', qr_code_url: '', status: 1, start_time: '', end_time: '', note: '' },
    filter: { text: '', send: '', group: '', page: 1, limit: 20 },
    pagination: { total: 0, currentPage: 1, perPage: 1 },
    error: {},

    headersGroup: [
      { text: '名前', field: 'name' },
      { text: '入校理由', field: 'reason' },
      { text: '状態', field: 'status', },
      { text: '開始日', field: 'start_time', },
      { text: '終了日', field: 'end_time', },
    ],
    groups: [],
    group: {},
    intGroup: { id: 0, name: '', reason: '', status: 1, start_time: '', end_time: '' },
    filterGroup: { text: '' },
    emailStatus: [
      // new, sending, success, error
      { text: '新規', value: 'new' },
      { text: '送信中', value: 'sending' },
      { text: '成功', value: 'success' },
      { text: 'エラー', value: 'error' },
    ],
  }),
  actions: {
    getStatusText(status) {
      return this.emailStatus.find(item => item.value == status)?.text ?? 'new'
    },
    async getItems() {
      const response = await axios.get(`/api/event-ticket?text=${this.filter.text}&send=${this.filter.send}&group=${this.filter.group}&page=${this.filter.page}&limit=${this.filter.limit}`)
      this.items = []
      this.pagination = { total: 0, currentPage: 1, perPage: 1 }
      //assign value
      if (response.status == 200 && response.data.total > 0) {
        this.items = response.data.data
        this.pagination.total = response.data.total
        this.pagination.currentPage = response.data.current_page
        this.pagination.perPage = response.data.per_page
      }
    },
    async fetchGroups() {
      const response = await axios.get(`/api/event-ticket/group?text=${this.filterGroup.text}`)
      this.groups = response.data ?? [];
    },

    async save() {
      const response = await axios.post(`/api/event-ticket`, this.item)
      if (response.data.code == 200) {
        this.getItems()
      }
      return response.data
    },

    async regenQRCode() {
      const response = await axios.get(`/api/event-ticket/regen-qrcode`)
      return response.data
    },

    async validateStaff() {
      this.error = {}
      if (ValidateHelper.isEmpty(this.item.kanji_name)) {
        this.error.kanji_name = true
      }
      if (ValidateHelper.isEmpty(this.item.romaji_name)) {
        this.error.romaji_name = true
      }
      if (this.item.groups.length == 0) {
        this.error.groups = true
      }
      // if (ValidateHelper.isEmpty(this.item.gender)) {
      //   this.error.gender = true
      // }
      if (!ValidateHelper.isEmpty(this.item.email) && !ValidateHelper.isEmail(this.item.email)) {
        this.error.email = true
      }

    },
    async validateGroup() {
      this.error = {};
      const nameSet = new Set();

      if (ValidateHelper.isEmpty(this.group.name)) {
        this.error.name = true;
      }
      if (ValidateHelper.isEmpty(this.group.start_time) || this.group.start_time == '0000-00-00 00:00:00') {
        this.error.start_time = true
      }
      if (!ValidateHelper.isEmpty(this.group.start_time)
        && !ValidateHelper.isEmpty(this.group.end_time)
        && this.group.start_time >= this.group.end_time) {
        this.error.start_time = true
        this.error.end_time = true
      }
      this.groups.forEach((group) => {
        if (group.name == this.group.name && group.id != this.group.id) {
          this.error.name = true;
        }
      });
    },
    async saveGroups() {
      const response = await axios.post(`api/event-ticket/group`, this.group)
      return response.data
    },

    async import(formData) {
      const response = await axios.post('/api/event-ticket/import', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response
    }
  }
})
